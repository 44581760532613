define("additive-content/components/ac-styles/configurator/preview", ["exports", "@ember/component", "@ember/template-factory", "additive-content/config/environment", "@glimmer/component", "@glimmer/tracking", "@ember/service", "prop-types", "ember-arg-types", "ember-concurrency", "lodash.merge", "additive-content/utils/render-utils"], function (_exports, _component, _templateFactory, _environment, _component2, _tracking, _service, _propTypes, _emberArgTypes, _emberConcurrency, _lodash, _renderUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="w-100 h-100"
    {{did-insert (perform this.renderBlock)}}
    {{did-update (perform this.renderBlock) this.block.id this.blocks @update}}
  >
    {{#if this.isError}}
      <div class="w-100 text-center font-sm red">
        {{t "routes.styles.style.preview.error"}}
      </div>
    {{else}}
      <iframe
        id="preview-frame"
        title="iframe"
        srcdoc={{this._previewHtml}}
        class="w-100 h-100"
        frameborder="0"
      ></iframe>
    {{/if}}
  </div>
  */
  {
    "id": "koeQPuYh",
    "block": "[[[11,0],[24,0,\"w-100 h-100\"],[4,[38,0],[[28,[37,1],[[30,0,[\"renderBlock\"]]],null]],null],[4,[38,2],[[28,[37,1],[[30,0,[\"renderBlock\"]]],null],[30,0,[\"block\",\"id\"]],[30,0,[\"blocks\"]],[30,1]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isError\"]],[[[1,\"    \"],[10,0],[14,0,\"w-100 text-center font-sm red\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"routes.styles.style.preview.error\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"iframe\"],[14,1,\"preview-frame\"],[14,\"title\",\"iframe\"],[15,\"srcdoc\",[30,0,[\"_previewHtml\"]]],[14,0,\"w-100 h-100\"],[14,\"frameborder\",\"0\"],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"@update\"],false,[\"did-insert\",\"perform\",\"did-update\",\"if\",\"t\"]]",
    "moduleName": "additive-content/components/ac-styles/configurator/preview.hbs",
    "isStrictMode": false
  });
  let AcStylesConfiguratorPreviewComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.array), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object), _dec4 = (0, _emberArgTypes.arg)(_propTypes.object), _dec5 = (0, _emberArgTypes.arg)(_propTypes.object), _dec6 = (0, _emberConcurrency.task)(function* () {
    var _this$blocks;
    this.isError = false;
    if (!((_this$blocks = this.blocks) !== null && _this$blocks !== void 0 && _this$blocks.length) && !this.block) {
      return;
    }
    function toKeyValue(property, uiCd) {
      const newObject = {};
      property.forEach(styleGroup => {
        styleGroup.properties.forEach(property => {
          if (property.sync) {
            if (property.type === 'typography') {
              newObject[property.id] = uiCd.corporateDesignTypography[property.syncedValue];
              newObject[property.id]['weight'] = uiCd.corporateDesignTypography[property.syncedValue]['weight'] || 400;
            } else {
              newObject[property.id] = property.syncedValue;
            }
          } else {
            newObject[property.id] = property.value;
          }
        });
      });
      return newObject;
    }

    // debounce rerender
    yield (0, _emberConcurrency.timeout)(400);
    yield (0, _emberConcurrency.waitForProperty)(this.uiCd, 'corporateDesignColors');
    const corporateDesign = {
      colors: this.uiCd.corporateDesignColors,
      typography: this.uiCd.corporateDesignTypography,
      fonts: this.uiCd.fonts
    };
    try {
      var _this$blocks2, _this$style;
      if (!this.contentElement) {
        const container = document.getElementById('preview-frame');
        if (!container) return;
        this.previewEl = container;
      }
      const blocks = (_this$blocks2 = this.blocks) !== null && _this$blocks2 !== void 0 && _this$blocks2.length ? this.blocks.map(b => (0, _renderUtils.formatBlock)(b.serialize({
        includeId: true
      }), this.typography, this.colors, this.uiCd, this.style)) : [(0, _renderUtils.formatBlock)(this.block.serialize({
        includeId: true
      }), this.typography, this.colors, this.uiCd)];
      const activeStyleBlocks = this.store.peekAll('style-block').map(styleBlock => {
        if (!styleBlock.active) {
          return null;
        }
        const blockData = styleBlock.toJSON();
        blockData.style = toKeyValue(blockData.style, this.uiCd);
        if (blockData.content) {
          blockData.content = toKeyValue(blockData.content, this.uiCd);
        }
        return blockData;
      }).filter(block => block);
      const renderRequestBody = {
        api_host: _environment.default.APP.publicHost,
        blocks,
        corporateDesign,
        layout: 'index',
        style: Object.assign({}, (_this$style = this.style) === null || _this$style === void 0 ? void 0 : _this$style.styles, {
          blocks: activeStyleBlocks
        })
      };
      const snakeCasedRequestBody = {};
      (0, _renderUtils.snakeCasePropertyKeys)(renderRequestBody, snakeCasedRequestBody);
      const response = yield fetch(`${_environment.default.APP.renderHost}/layout`, {
        method: 'POST',
        body: JSON.stringify(snakeCasedRequestBody)
      });
      if (!response.ok) {
        throw new Error('Response not ok');
      }
      const html = yield response.text();

      // disable interaction on preview
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(html, 'text/html');
      const headTag = parsedHtml === null || parsedHtml === void 0 ? void 0 : parsedHtml.documentElement.getElementsByTagName('head');
      if (headTag.length > 0) {
        const styleTag = document.createElement('style');
        headTag[0].appendChild(styleTag);
        styleTag.appendChild(document.createTextNode(`
            body {
              position: relative;
            }

            body:after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
              pointer-events: all;
            };
          `));
      }
      this._previewHtml = parsedHtml.documentElement.outerHTML;
    } catch (e) {
      this.isError = true;
      throw new Error(e);
    }
  }).restartable(), _class = class AcStylesConfiguratorPreviewComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiCd", _descriptor, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "block", _descriptor4, this);
      _initializerDefineProperty(this, "blocks", _descriptor5, this);
      _initializerDefineProperty(this, "style", _descriptor6, this);
      _initializerDefineProperty(this, "styleBlocks", _descriptor7, this);
      _initializerDefineProperty(this, "previewEl", _descriptor8, this);
      _initializerDefineProperty(this, "isError", _descriptor9, this);
      /**
       * the corporate design of the organization
       *
       * @argument corporateDesign
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "corporateDesign", _descriptor10, this);
      /**
       * the fetched preview HTML
       *
       * @property _previewHtml
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_previewHtml", _descriptor11, this);
      _initializerDefineProperty(this, "renderBlock", _descriptor12, this);
    }
    get colors() {
      return (0, _renderUtils.getColors)((0, _lodash.default)({}, this.style.styles.colors));
    }
    get typography() {
      return (0, _renderUtils.getTypography)((0, _lodash.default)({}, this.style.styles.typography), this.colors, this.uiCd);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiCd", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "block", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "blocks", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "style", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "styleBlocks", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "previewEl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "corporateDesign", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_previewHtml", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "renderBlock", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcStylesConfiguratorPreviewComponent);
});
define("additive-content/serializers/application", ["exports", "@ember-data/serializer/rest", "@ember/utils"], function (_exports, _rest, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ApplicationSerializer extends _rest.default {
    constructor() {
      super(...arguments);
      /**
       * can be overwritten to prevent the serializer from
       * removing a property with a null value
       *
       * @property nullableProperties
       * @type {[String]}
       */
      _defineProperty(this, "nullableProperties", null);
    }
    serialize(snapshot, options) {
      var json = super.serialize(snapshot, options);
      Object.keys(json).forEach(key => (0, _utils.isNone)(json[key]) && (this.nullableProperties || []).indexOf(key) < 0 && delete json[key]);
      return json;
    }
    serializeIntoHash(data, type, record, options) {
      Object.assign(data, this.serialize(record, options));
    }

    /**
     * adds the nullable properties to the payload, with value null
     */
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const modelName = Object.keys(payload)[0];
      const data = Array.isArray(payload[modelName]) ? payload[modelName] : [payload[modelName]];
      this.nullableProperties && data.forEach(model => {
        this.nullableProperties.forEach(property => {
          if (!model[property]) {
            model[property] = null;
          }
        });
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
  }
  _exports.default = ApplicationSerializer;
});
define("additive-content/templates/instance/contents/daily-menus/index/create", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ip7n2zCj",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@validation\",\"@isNew\",\"@categorizableType\",\"@modelName\",\"@contentTypeRoute\"],[[30,0,[\"model\"]],[30,0,[\"validation\"]],true,\"daily_menu\",\"daily-menu\",\"daily-menus\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[16,\"data-test-daily-menu-input-name\",true]],[[\"@label\",\"@value\",\"@isReadOnly\",\"@errorMessage\",\"@isTouched\"],[[28,[37,2],[\"contents.common.properties.title\"],null],[30,1,[\"changeset\",\"name\"]],[30,1,[\"isReadOnly\"]],[30,1,[\"changeset\",\"error\",\"name\",\"validation\"]],[30,1,[\"isTouched\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[16,\"data-test-daily-menu-input-courses\",true]],[[\"@label\",\"@initialValue\",\"@onChange\",\"@isReadOnly\",\"@isAiFeatureEnabled\",\"@errorMessage\",\"@isTouched\"],[[28,[37,2],[\"contents.dailyMenus.dailyMenu.properties.courses\"],null],[30,1,[\"changeset\",\"courses\"]],[28,[37,4],[[30,1,[\"changeset\"]],\"courses\"],null],[30,1,[\"isReadOnly\"]],true,[30,1,[\"changeset\",\"error\",\"courses\",\"validation\",\"firstObject\"]],[30,1,[\"isTouched\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[1,\"\\n  \"],[8,[30,1,[\"detail\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[16,\"data-test-daily-menu-input-date\",true]],[[\"@label\",\"@initialDate\",\"@onConfirm\",\"@placeholder\",\"@errorMessage\",\"@isTouched\"],[[28,[37,2],[\"contents.dailyMenus.dailyMenu.properties.date\"],null],[30,1,[\"changeset\",\"date\"]],[28,[37,6],[[30,0,[\"onDateChange\"]],[30,1,[\"changeset\"]]],null],[28,[37,2],[\"uiDatetimePicker.date\"],null],[30,1,[\"changeset\",\"error\",\"date\",\"validation\"]],[30,1,[\"isTouched\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"contentView\"],false,[\"ac-contents/edit\",\"ui-input\",\"t\",\"ui-editor\",\"changeset-set\",\"ui-datepicker\",\"fn\"]]",
    "moduleName": "additive-content/templates/instance/contents/daily-menus/index/create.hbs",
    "isStrictMode": false
  });
});
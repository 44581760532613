define("additive-content/router", ["exports", "@ember/routing/router", "additive-content/config/environment", "@additive-apps/auth/router"], function (_exports, _router, _environment, _router2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends _router.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    // callback route
    this.route('auth', function () {
      (0, _router2.default)(this);
    });

    // icon route is needed to display icons for Progressive Web App
    this.route('icons');
    this.route('instance', {
      path: ':instance_id'
    }, function () {
      this.route('contents', {
        path: '/'
      }, function () {
        this.mount('@additive-apps/multimedia-engine', {
          as: 'additive-multimedia-engine',
          path: 'mm'
        });
        this.route('hotel-infos', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('hotel-info', {
              path: ':id'
            }, function () {
              this.route('edit');
            });
            this.route('create');
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('treatments', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('treatment', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('pages', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('page', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('tours', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('tour', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('products', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('product', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('webcams', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('webcam', {
              path: ':id'
            }, function () {
              this.route('edit');
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('posts', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('post', {
              path: ':id'
            }, function () {
              this.route('index', {
                path: '/'
              });
              this.route('comments');
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('redirected');
        });
        this.route('room-services', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('room-service', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('people', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('person', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('rooms', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('room', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('last-minute-offers', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('last-minute-offer', {
              path: ':id'
            }, function () {
              this.route('edit');
            });
            this.route('create');
          });
        });
        this.route('surveys', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('survey', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('offers', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('offer', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('prices');
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('events', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('event', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('daily-menus', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('daily-menu', {
              path: ':id'
            }, function () {
              this.route('edit');
            });
            this.route('create');
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('frequently-asked-questions', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('frequently-asked-question', {
              path: ':id'
            }, function () {
              this.route('edit');
            });
            this.route('create');
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('galleries', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('gallery', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('quotes', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('quote', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('points-of-interest', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('point-of-interest', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
        this.route('rates', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('create');
            this.route('season', {
              path: ':id'
            }, function () {
              this.route('edit');
            });
          });
          this.route('rates', function () {
            this.route('rate', {
              path: ':id'
            }, function () {
              this.route('edit');
            });
            this.route('create');
          });
        });
        this.route('portal-scores', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('portal-score', {
              path: ':id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
          this.route('categories', function () {
            this.route('category', {
              path: ':id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('create', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
          });
        });
      });
      this.route('landing-pages', function () {
        this.route('landing-page', {
          path: ':landing_page_id'
        }, function () {
          this.route('edit', function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
        });
      });
      this.route('multimedia', function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.mount('@additive-apps/multimedia-engine', {
            as: 'additive-multimedia-engine',
            path: 'mm'
          });
        });
      });
      this.route('settings', function () {
        this.route('domains');
        this.route('integrations');
        this.route('landingpage');
        this.route('legal');
      });
      this.route('auditlog');
      this.route('styles', function () {
        this.route('style', {
          path: ':style_id'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('block', {
            path: ':block_id'
          }, function () {
            this.route('variation', {
              path: ':variation_id'
            });
          });
        });
        this.route('widgets', function () {
          this.route('widget', {
            path: ':widget_id'
          });
        });
      });
    });
    this.route('page-not-found', {
      path: 'not-found'
    });
    this.route('page-not-found', {
      path: '*wildcard'
    });
  });
});
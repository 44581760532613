define("additive-content/components/ac-widgets/detail-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "prop-types", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "additive-content/validations/widget", "@additive-apps/utils/utils/errors"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _emberArgTypes, _propTypes, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _widget, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert this.setup}}>
    <UiModal @title={{this.dialogTitle}} @size="sm" @onClose={{this._onClose}} as |modal|>
      <modal.header />
      <modal.content as |content|>
        <content.body>
          <UiInput
            @label={{t "components.acWidgets.detailDialog.labels.name"}}
            @value={{this._changeset.name}}
            @isTouched={{this._isValidated}}
            @errorMessage={{this._changeset.error.name.validation.firstObject}}
          />
          <UiInput
            @label={{t "components.acWidgets.detailDialog.labels.description"}}
            @value={{this._changeset.description}}
            @textarea={{true}}
            @isTouched={{this._isValidated}}
          />
        </content.body>
      </modal.content>
      <modal.footer>
        <UiButton
          @label={{t "global.actions.save"}}
          @onClick={{perform this._save}}
          @isPromise={{true}}
          @isSecondary={{true}}
        />
      </modal.footer>
    </UiModal>
  </div>
  */
  {
    "id": "Xg+6Po1S",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@title\",\"@size\",\"@onClose\"],[[30,0,[\"dialogTitle\"]],\"sm\",[30,0,[\"_onClose\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@isTouched\",\"@errorMessage\"],[[28,[37,3],[\"components.acWidgets.detailDialog.labels.name\"],null],[30,0,[\"_changeset\",\"name\"]],[30,0,[\"_isValidated\"]],[30,0,[\"_changeset\",\"error\",\"name\",\"validation\",\"firstObject\"]]]],null],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@textarea\",\"@isTouched\"],[[28,[37,3],[\"components.acWidgets.detailDialog.labels.description\"],null],[30,0,[\"_changeset\",\"description\"]],true,[30,0,[\"_isValidated\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n    \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@label\",\"@onClick\",\"@isPromise\",\"@isSecondary\"],[[28,[37,3],[\"global.actions.save\"],null],[28,[37,5],[[30,0,[\"_save\"]]],null],true,true]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13]],[\"modal\",\"content\"],false,[\"did-insert\",\"ui-modal\",\"ui-input\",\"t\",\"ui-button\",\"perform\"]]",
    "moduleName": "additive-content/components/ac-widgets/detail-dialog.hbs",
    "isStrictMode": false
  });
  let AcWidgetsDetailDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _dec3 = (0, _emberConcurrency.task)(function* () {
    const changeset = this._changeset;
    try {
      yield changeset.validate();
      this._isValidated = true;

      // empty styles as they are handled in the configurator
      changeset.set('styles', []);
      if (!changeset.get('isValid')) {
        throw new Error();
      }
      if (changeset.get('isPristine')) {
        return this.onClose();
      }
      yield changeset.save();
      if (this._isCreateDialog) {
        yield this.uiFilter.reset('widgets');
      }
      this.onClose();
    } catch (e) {
      const error = (0, _errors.getAdapterError)(e);
      if (error === 'This value is already used.') {
        changeset.pushErrors('name', this.intl.t('errors.nameExists'));
      }
    }
  }), _class = class AcWidgetsDetailDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor3, this);
      /**
       * the widget resource
       *
       * @argument widget
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "widget", _descriptor4, this);
      /**
       * the changeset of the widget
       *
       * @property _changeset
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "_changeset", _descriptor5, this);
      /**
       * whether dialog is opened in create mode
       *
       * @property _isCreateDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isCreateDialog", _descriptor6, this);
      /**
       * whether the changeset has been validated already at least once
       *
       * @property _isValidated
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isValidated", _descriptor7, this);
      _initializerDefineProperty(this, "onClose", _descriptor8, this);
      _initializerDefineProperty(this, "_save", _descriptor9, this);
    }
    get dialogTitle() {
      const intlText = this._isCreateDialog ? 'create' : 'edit';
      return this.intl.t(`components.acWidgets.detailDialog.title.${intlText}`);
    }
    setup() {
      if (this.widget.isNew) {
        this._isCreateDialog = true;
      }
      const validation = (0, _widget.widgetValidation)(this.intl);
      const changeset = new _emberChangeset.default(this.widget, (0, _emberChangesetValidations.default)(validation), validation);
      this._changeset = changeset;
    }
    _onClose() {
      const changeset = this._changeset;
      const widget = this.widget;
      if (changeset.get('isDirty')) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          changeset.destroy();
          widget.deleteRecord();
          this.onClose();
        });
        return;
      }
      changeset.destroy();
      widget.deleteRecord();
      this.onClose();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "widget", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isCreateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_isValidated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_save", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClose"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcWidgetsDetailDialog);
});
define("additive-content/components/ac-widgets/ac-widget-configuration/detail-navbar", ["exports", "@ember/component", "@ember/template-factory", "additive-content/config/environment", "@glimmer/component", "@ember/service", "@ember/object/computed", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _environment, _component2, _service, _computed, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiNavigationDrawer @navDrawerStateKey="widget-detail" @isRight={{true}} @isPermanent={{false}}>
    <UiToolbar @class="no-shadow" @isSecondary={{true}}>
      <UiActionButton @icon="close" @label={{t "global.actions.close"}} @onClick={{this.onClose}} />
    </UiToolbar>
    <div class="flex flex-column pa2 pa3-sm">
      <UiLangSelect
        @selected={{this.selectedLanguage}}
        @languages={{this.languages}}
        @publishedLanguages={{this.languages}}
        @availableLanguages={{this.languages}}
        @defaultLanguage={{this.defaultLanguage}}
        @hideLanguageActions={{true}}
        @hideState={{true}}
        @onSelect={{fn (mut this.selectedLanguage)}}
      />
      <UiInput @textarea={{true}} @value={{this._code}} @rows={{10}} @isReadOnly={{true}} />
    </div>
  </UiNavigationDrawer>
  */
  {
    "id": "WCbws5fm",
    "block": "[[[8,[39,0],null,[[\"@navDrawerStateKey\",\"@isRight\",\"@isPermanent\"],[\"widget-detail\",true,false]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@class\",\"@isSecondary\"],[\"no-shadow\",true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@label\",\"@onClick\"],[\"close\",[28,[37,3],[\"global.actions.close\"],null],[30,0,[\"onClose\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-column pa2 pa3-sm\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@selected\",\"@languages\",\"@publishedLanguages\",\"@availableLanguages\",\"@defaultLanguage\",\"@hideLanguageActions\",\"@hideState\",\"@onSelect\"],[[30,0,[\"selectedLanguage\"]],[30,0,[\"languages\"]],[30,0,[\"languages\"]],[30,0,[\"languages\"]],[30,0,[\"defaultLanguage\"]],true,true,[28,[37,5],[[28,[37,6],[[30,0,[\"selectedLanguage\"]]],null]],null]]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@textarea\",\"@value\",\"@rows\",\"@isReadOnly\"],[true,[30,0,[\"_code\"]],10,true]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"ui-navigation-drawer\",\"ui-toolbar\",\"ui-action-button\",\"t\",\"ui-lang-select\",\"fn\",\"mut\",\"ui-input\"]]",
    "moduleName": "additive-content/components/ac-widgets/ac-widget-configuration/detail-navbar.hbs",
    "isStrictMode": false
  });
  let AcWidgetConfigurationDetailNavbar = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _dec2 = (0, _computed.alias)('uiAppSettings.languages.defaultLanguage'), _dec3 = (0, _computed.alias)('uiAppSettings.languages.contentLanguages'), _dec4 = (0, _emberArgTypes.arg)(_propTypes.object), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AcWidgetConfigurationDetailNavbar extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _initializerDefineProperty(this, "organizationSlug", _descriptor3, this);
      _initializerDefineProperty(this, "defaultLanguage", _descriptor4, this);
      _initializerDefineProperty(this, "languages", _descriptor5, this);
      _initializerDefineProperty(this, "widget", _descriptor6, this);
      _initializerDefineProperty(this, "selectedLanguage", _descriptor7, this);
    }
    get _code() {
      return `<div id="${this.widget.selector}" class="aa-journal-widget"></div><script async src="${_environment.default.APP.publicHost}/${this.organizationSlug}/widgets/${this.widget.id}/${this.selectedLanguage}"></script>`;
    }
    onClose() {}
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "defaultLanguage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "languages", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "widget", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedLanguage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'de';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcWidgetConfigurationDetailNavbar);
});
define("additive-content/templates/instance/contents/webcams/index/create", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qujWG5YA",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@validation\",\"@isNew\",\"@categorizableType\",\"@modelName\",\"@contentTypeRoute\"],[[30,0,[\"model\"]],[30,0,[\"validation\"]],true,\"webcam\",\"webcam\",\"webcams\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[16,\"data-test-product-input-name\",true]],[[\"@label\",\"@value\",\"@isReadOnly\",\"@errorMessage\",\"@isTouched\"],[[28,[37,2],[\"contents.common.properties.title\"],null],[30,1,[\"changeset\",\"name\"]],[30,1,[\"isReadOnly\"]],[30,1,[\"changeset\",\"error\",\"name\",\"validation\"]],[30,1,[\"isTouched\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[16,\"data-test-product-input-description\",true]],[[\"@label\",\"@initialValue\",\"@onChange\",\"@isAiFeatureEnabled\",\"@isReadOnly\",\"@errorMessage\",\"@isTouched\"],[[28,[37,2],[\"contents.common.properties.description\"],null],[30,1,[\"changeset\",\"description\"]],[28,[37,4],[[30,1,[\"changeset\"]],\"description\"],null],true,[30,1,[\"isReadOnly\"]],[30,1,[\"changeset\",\"error\",\"description\",\"validation\",\"firstObject\"]],[30,1,[\"isTouched\"]]]],null],[1,\"\\n\\n    \"],[8,[39,1],[[16,\"data-test-webcams-input-url\",true]],[[\"@label\",\"@value\",\"@isReadOnly\",\"@errorMessage\",\"@isTouched\"],[[28,[37,2],[\"contents.webcams.webcam.properties.url\"],null],[30,1,[\"changeset\",\"url\"]],[30,1,[\"isReadOnly\"]],[30,1,[\"changeset\",\"error\",\"url\",\"validation\"]],[30,1,[\"isTouched\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]]],[\"contentView\"],false,[\"ac-contents/edit\",\"ui-input\",\"t\",\"ui-editor\",\"changeset-set\"]]",
    "moduleName": "additive-content/templates/instance/contents/webcams/index/create.hbs",
    "isStrictMode": false
  });
});
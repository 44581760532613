define("additive-content/controllers/instance/contents/rooms/index/room/edit", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "additive-content/utils/slug", "additive-content/utils/constants", "@additive-apps/ui/constants", "@ember/template"], function (_exports, _controller, _tracking, _object, _computed, _service, _slug, _constants, _constants2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceContentsRoomsIndexRoomEditController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.apps'), _class = class InstanceContentsRoomsIndexRoomEditController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor3, this);
      _defineProperty(this, "queryParams", ['language']);
      _defineProperty(this, "priceInformationOptions", ['per_person', 'per_room']);
      _initializerDefineProperty(this, "accessibleApps", _descriptor4, this);
      _initializerDefineProperty(this, "language", _descriptor5, this);
      _initializerDefineProperty(this, "validation", _descriptor6, this);
      // Initialize with true, because slug is a required field at room creation
      _initializerDefineProperty(this, "isSlugSet", _descriptor7, this);
      _initializerDefineProperty(this, "isHelpDialog", _descriptor8, this);
    }
    get isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
    get priceInformations() {
      return this.priceInformationOptions.map(key => ({
        value: key,
        name: this.intl.t(`contents.rooms.room.priceInformations.${key}`)
      }));
    }
    get roomTypes() {
      return _constants.ROOM_TYPES.map(type => ({
        value: type,
        name: this.intl.t(`contents.rooms.room.types.${type}`)
      }));
    }
    get labelSymbolTypes() {
      return _constants.LABEL_SYMBOL_TYPES.map(type => ({
        value: type,
        name: this.intl.t(`contents.common.labelSymbolTypes.${type}`)
      }));
    }

    /**
     * Whether the organization has Marketing Cloud
     *
     * @computed hasMC
     * @type {Boolean}
     * @private
     */
    get hasMC() {
      if (!this.accessibleApps) {
        return false;
      }
      return this.accessibleApps.find(app => {
        return app.id === _constants2.APP_ID_MARKETING_CLOUD;
      });
    }
    get popoverText() {
      if (this.hasMC) {
        return (0, _template.htmlSafe)(this.intl.t('contents.rooms.room.imageLabels.description'));
      }
      return '';
    }
    onSlugChange(value) {
      this.isSlugSet = value ? true : false;
    }
    onLabelSymbolChange(changeset, symbol) {
      changeset.set('labelSymbol', symbol.value);
    }
    generateSlug(changeset, title) {
      // Do nothing if slug was set by the user
      if (this.isSlugSet) {
        return;
      }
      const slug = (0, _slug.stringToSlug)(title);
      changeset.set('slug', slug);
    }
    onOccupancyChange(changeset, field, value) {
      changeset.set(field, value);
      changeset.validate();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "accessibleApps", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "language", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "validation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isSlugSet", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isHelpDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSlugChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSlugChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLabelSymbolChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onLabelSymbolChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateSlug", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generateSlug"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOccupancyChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOccupancyChange"), _class.prototype), _class);
});
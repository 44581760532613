define("additive-content/components/ac-contents/rooms/settings-navdrawer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "prop-types", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _propTypes, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AcContents::SettingsNavdrawer
    @stateKey={{this.stateKey}}
    @type="rooms"
    @subtitle={{t "routes.contents.rooms.title"}}
    @onClose={{this.toggleDrawer}}
    @validation={{this.validation}} as |nav|
  >
    <UiControlPanelGroup @title={{t "contents.rooms.settings.import"}} @classNames="pb2" as |Group|>
      <Group.panel
        @classNames="bt b--secondary-10 w-100"
        @label={{t "contents.rooms.settings.syncRooms"}}
        @value={{if
          nav.changeset.lastRoomsSyncAt
          (t
            "contents.rooms.settings.lastSyncAt"
            date=(format-date
              nav.changeset.lastRoomsSyncAt
              format="short"
              year="numeric"
              hour="numeric"
              minute="numeric"
            )
          )
          ""
        }}
        @noink={{true}} as |panel|
      >
        <panel.right>
          <UiSwitch
            @value={{nav.changeset.syncRooms}}
            @onChange={{pipe (fn (mut nav.changeset.syncRooms)) nav.onChange}}
            @isReadOnly={{this.isReadOnly}}
          />
        </panel.right>
      </Group.panel>
    </UiControlPanelGroup>
    <div class="secondary font-sm">
      {{t "contents.rooms.settings.description"}}
    </div>
  </AcContents::SettingsNavdrawer>
  
  */
  {
    "id": "Zr53W0yt",
    "block": "[[[8,[39,0],null,[[\"@stateKey\",\"@type\",\"@subtitle\",\"@onClose\",\"@validation\"],[[30,0,[\"stateKey\"]],\"rooms\",[28,[37,1],[\"routes.contents.rooms.title\"],null],[30,0,[\"toggleDrawer\"]],[30,0,[\"validation\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@title\",\"@classNames\"],[[28,[37,1],[\"contents.rooms.settings.import\"],null],\"pb2\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"panel\"]],null,[[\"@classNames\",\"@label\",\"@value\",\"@noink\"],[\"bt b--secondary-10 w-100\",[28,[37,1],[\"contents.rooms.settings.syncRooms\"],null],[52,[30,1,[\"changeset\",\"lastRoomsSyncAt\"]],[28,[37,1],[\"contents.rooms.settings.lastSyncAt\"],[[\"date\"],[[28,[37,4],[[30,1,[\"changeset\",\"lastRoomsSyncAt\"]]],[[\"format\",\"year\",\"hour\",\"minute\"],[\"short\",\"numeric\",\"numeric\",\"numeric\"]]]]]],\"\"],true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@value\",\"@onChange\",\"@isReadOnly\"],[[30,1,[\"changeset\",\"syncRooms\"]],[28,[37,6],[[28,[37,7],[[28,[37,8],[[30,1,[\"changeset\",\"syncRooms\"]]],null]],null],[30,1,[\"onChange\"]]],null],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[10,0],[14,0,\"secondary font-sm\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"contents.rooms.settings.description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"nav\",\"Group\",\"panel\"],false,[\"ac-contents/settings-navdrawer\",\"t\",\"ui-control-panel-group\",\"if\",\"format-date\",\"ui-switch\",\"pipe\",\"fn\",\"mut\"]]",
    "moduleName": "additive-content/components/ac-contents/rooms/settings-navdrawer.hbs",
    "isStrictMode": false
  });
  /**
   * component for the room settings
   *
   * @class AcContentsRoomsSettingsNavdrawerComponent
   */
  let AcContentsRoomsSettingsNavdrawerComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _class = class AcContentsRoomsSettingsNavdrawerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiState", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "stateKey", _descriptor3, this);
      /**
       * whether component is read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor4, this);
      _initializerDefineProperty(this, "validation", _descriptor5, this);
    }
    toggleDrawer() {
      this.uiState.getState(this.stateKey).toggle();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stateKey", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "validation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDrawer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDrawer"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsRoomsSettingsNavdrawerComponent);
});
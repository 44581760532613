define("additive-content/models/post", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "date-fns"], function (_exports, _model, _object, _computed, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PostModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec10 = (0, _model.hasMany)('category', {
    async: true
  }), _dec11 = (0, _model.hasMany)('comment', {
    async: true
  }), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('date'), _dec14 = (0, _model.attr)('date'), _dec15 = (0, _model.attr)('date'), _dec16 = (0, _model.attr)('date'), _dec17 = (0, _model.attr)('boolean'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _computed.gt)('media.length', 0), _dec20 = (0, _computed.alias)('media.firstObject'), _class = class PostModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "excerpt", _descriptor2, this);
      _initializerDefineProperty(this, "text", _descriptor3, this);
      _initializerDefineProperty(this, "slug", _descriptor4, this);
      _initializerDefineProperty(this, "personId", _descriptor5, this);
      _initializerDefineProperty(this, "url", _descriptor6, this);
      _initializerDefineProperty(this, "shareableUrl", _descriptor7, this);
      _initializerDefineProperty(this, "commentsCount", _descriptor8, this);
      _initializerDefineProperty(this, "allowComments", _descriptor9, this);
      _initializerDefineProperty(this, "categories", _descriptor10, this);
      _initializerDefineProperty(this, "comments", _descriptor11, this);
      _initializerDefineProperty(this, "tags", _descriptor12, this);
      _initializerDefineProperty(this, "interests", _descriptor13, this);
      _initializerDefineProperty(this, "travelMotivations", _descriptor14, this);
      _initializerDefineProperty(this, "travelTimes", _descriptor15, this);
      _initializerDefineProperty(this, "availableLanguages", _descriptor16, this);
      _initializerDefineProperty(this, "publishedLanguages", _descriptor17, this);
      _initializerDefineProperty(this, "language", _descriptor18, this);
      _initializerDefineProperty(this, "updatedAt", _descriptor19, this);
      _initializerDefineProperty(this, "createdAt", _descriptor20, this);
      _initializerDefineProperty(this, "publicFrom", _descriptor21, this);
      _initializerDefineProperty(this, "publicUntil", _descriptor22, this);
      _initializerDefineProperty(this, "timeLimited", _descriptor23, this);
      _initializerDefineProperty(this, "publishedState", _descriptor24, this);
      _initializerDefineProperty(this, "media", _descriptor25, this);
      _initializerDefineProperty(this, "hasImage", _descriptor26, this);
      _initializerDefineProperty(this, "primaryImage", _descriptor27, this);
      _defineProperty(this, "allInterestTags", []);
    }
    get primaryImage() {
      return this.slug && {
        url: this.slug
      };
    }
    get categoryNames() {
      return this.categories.map(category => category.name).join(', ');
    }
    get subDescription() {
      return this.categoryNames + (this.createdAt ? ' - ' + (0, _dateFns.format)(this.createdAt, 'dd.MM.yyyy') : '');
    }
    setAllInterestTags() {
      var _this$interests;
      this.allInterestTags = (_this$interests = this.interests) === null || _this$interests === void 0 ? void 0 : _this$interests.concat(this.travelMotivations, this.travelTimes);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "excerpt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "text", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "personId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "shareableUrl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "commentsCount", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allowComments", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "comments", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "tags", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "interests", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "travelMotivations", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "travelTimes", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "availableLanguages", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "publishedLanguages", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "publicFrom", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "publicUntil", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "timeLimited", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "publishedState", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "media", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "hasImage", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "primaryImage", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setAllInterestTags", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setAllInterestTags"), _class.prototype), _class);
});
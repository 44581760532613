define("additive-content/components/ac-contents/posts/published-url", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ac-publish-dialog_published-url mw-100 flex flex-column items-start flex-row-sm font-sm"
  >
    <div
      class="url-wrapper dif mw-100 secondary font-medium pv1 pr2 pl1 bg-secondary--15 br1 cpointer"
      role="button"
      {{on "click" this.copyUrl}}
    >
      {{svg-jar "clipboard" class="clipboard-icon mr1"}}
      <div class="ellipsis">
        {{this._prettifiedUrl}}
      </div>
    </div>
    <a
      class="link-wrapper dib secondary bg-secondary--15 br1 mt1 ml1-sm mt0-sm"
      href="{{this.url}}"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span class="flex items-center justify-center">
        {{svg-jar "external-link" width="20" height="20"}}
      </span>
    </a>
  </div>
  */
  {
    "id": "4zoS3Z3d",
    "block": "[[[10,0],[14,0,\"ac-publish-dialog_published-url mw-100 flex flex-column items-start flex-row-sm font-sm\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"url-wrapper dif mw-100 secondary font-medium pv1 pr2 pl1 bg-secondary--15 br1 cpointer\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,0,[\"copyUrl\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"clipboard\"],[[\"class\"],[\"clipboard-icon mr1\"]]]],[1,\"\\n    \"],[10,0],[14,0,\"ellipsis\"],[12],[1,\"\\n      \"],[1,[30,0,[\"_prettifiedUrl\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,3],[14,0,\"link-wrapper dib secondary bg-secondary--15 br1 mt1 ml1-sm mt0-sm\"],[15,6,[29,[[30,0,[\"url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"flex items-center justify-center\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"external-link\"],[[\"width\",\"height\"],[\"20\",\"20\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"on\",\"svg-jar\"]]",
    "moduleName": "additive-content/components/ac-contents/posts/published-url.hbs",
    "isStrictMode": false
  });
  let AcPublishDialogPublishedUrlComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AcPublishDialogPublishedUrlComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      _initializerDefineProperty(this, "url", _descriptor3, this);
    }
    get _prettifiedUrl() {
      var _this$url;
      return (_this$url = this.url) === null || _this$url === void 0 ? void 0 : _this$url.replace(/^https?:\/\//, '');
    }
    copyUrl() {
      var _navigator;
      (_navigator = navigator) === null || _navigator === void 0 || (_navigator = _navigator.clipboard) === null || _navigator === void 0 || _navigator.writeText(this.url);
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('components.acPublishDialog.publishedUrl.copySuccess')
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "copyUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyUrl"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcPublishDialogPublishedUrlComponent);
});
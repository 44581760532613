define("additive-content/controllers/instance/styles/index", ["exports", "@ember/controller", "@ember/object", "@ember/runloop", "@ember/service", "@glimmer/tracking", "ember-concurrency"], function (_exports, _controller, _object, _runloop, _service, _tracking, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceStylesIndexController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    this.isError = false;
    try {
      let tasks = [];
      tasks.push(this.store.findAll('style'));
      tasks.push((0, _emberConcurrency.timeout)(400));
      let [styles] = yield (0, _emberConcurrency.all)(tasks);
      this.styles = styles;

      // load corporate design for preview
      if (this.styles.length > 0) {
        this.uiCd.fetchCorporateDesign.perform();
      }
    } catch (error) {
      this.isError = true;
    }
  }), _class = class InstanceStylesIndexController extends _controller.default {
    /**
     * whether there are no styles
     *
     * @computed isEmpty
     * @type {Boolean}
     */
    get isEmpty() {
      var _this$styles;
      return ((_this$styles = this.styles) === null || _this$styles === void 0 ? void 0 : _this$styles.length) === 0;
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiCd", _descriptor6, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor7, this);
      _initializerDefineProperty(this, "uiToast", _descriptor8, this);
      /**
       * the style that is edited
       *
       * @property activeStyle
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "activeStyle", _descriptor9, this);
      /**
       * whether the detail dialog is open
       *
       * @property isDetailDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDetailDialog", _descriptor10, this);
      /**
       * whether the duplicate dialog is open
       *
       * @property isDuplicateDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDuplicateDialog", _descriptor11, this);
      /**
       * whether an error occured while fetching the styles
       *
       * @property isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor12, this);
      /**
       * whether a mobile device is used
       *
       * @property isMobile
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isMobile", _descriptor13, this);
      /**
       * the fetched styles
       *
       * @property styles
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "styles", _descriptor14, this);
      _initializerDefineProperty(this, "fetchStyles", _descriptor15, this);
      this.resizeListener = (0, _runloop.bind)(this, this._checkWindow);
      window.addEventListener('resize', this.resizeListener, false);
      (0, _runloop.scheduleOnce)('afterRender', this, this.resizeListener);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      window.removeEventListener('resize', this.resizeListener, false);
    }

    /**
     * Checks the current windowsize programmatically and sets the `isMobile`-prop
     * to true if the current window size is lower than `600px`.
     *
     * @function _checkWindow
     * @private
     * @return {void}
     */
    _checkWindow() {
      this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
    }
    edit(style) {
      if (this.isMobile) {
        return this.uiDialog.showError(this.intl.t('global.errors.desktopOnly.title'), this.intl.t('global.errors.desktopOnly.layout'));
      }
      this.router.transitionTo('instance.styles.style', style.id);
    }
    onEdit(style) {
      this.activeStyle = style;
      this.isDetailDialog = true;
    }
    duplicateStyle(style) {
      this.activeStyle = style;
      this.isDuplicateDialog = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiCd", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "activeStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isDetailDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isDuplicateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isMobile", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "styles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "fetchStyles", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "duplicateStyle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "duplicateStyle"), _class.prototype), _class);
});
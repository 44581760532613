define("additive-content/serializers/season-room", ["exports", "additive-content/serializers/application", "additive-content/utils/currency-serialization"], function (_exports, _application, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const AMOUNT_PROPERTIES = ['noServicePrice', 'halfBoardPrice', 'breakfastPrice', 'fullBoardPrice', 'allInclusivePrice'];
  class SeasonRoomSerializer extends _application.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "primaryKey", 'roomId');
    }
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      super.normalizeResponse(...arguments);

      // check if payload contains array or single content
      const firstKey = Object.keys(payload)[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];

      // transform currency properties and sort images
      contents.forEach(content => {
        AMOUNT_PROPERTIES.forEach(property => {
          if (content[property]) {
            content[property] = (0, _currencySerialization.centsToAmount)(content[property]).toString();
          }
        });
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);

      // transform currency properties to cents
      AMOUNT_PROPERTIES.forEach(property => {
        if (json[property]) {
          json[property] = (0, _currencySerialization.amountToCents)(json[property]);
        }
      });
      return json;
    }
  }
  _exports.default = SeasonRoomSerializer;
});
define("additive-content/components/ac-contents/offers/timespan-room-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "ember-arg-types", "prop-types", "@glimmer/tracking", "ember-changeset-validations", "ember-changeset", "additive-content/validations/timespan"], function (_exports, _component, _templateFactory, _component2, _service, _object, _emberArgTypes, _propTypes, _tracking, _emberChangesetValidations, _emberChangeset, _timespan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal @title={{this.model.name}} @onClose={{this.close}} @size="sm" as |m|>
    <m.header />
    <m.content @classNames="pb0" as |c|>
      <c.body {{did-insert this.didInsert}} {{will-destroy this.willDestroy}}>
        <UiInput
          @label={{t "contents.offers.offer.timespan.properties.price"}}
          @value={{this.changeset.amount}}
          @errorMessage={{this.changeset.error.amount.validation}} as |input|
        >
          <input.right>
            {{currency-symbol this.uiAppSettings.currency}}
          </input.right>
        </UiInput>
      </c.body>
    </m.content>
    <m.footer>
      <UiButton @label={{t "global.actions.save"}} @isSecondary={{true}} @onClick={{this.save}} />
    </m.footer>
  </UiModal>
  
  <UiDiscardChanges @isOpen={{this.isDiscardChangesDialog}} @onDiscard={{this.discardChanges}} />
  */
  {
    "id": "TMLZ4nAS",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onClose\",\"@size\"],[[30,0,[\"model\",\"name\"]],[30,0,[\"close\"]],\"sm\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\"],[\"pb0\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],[[4,[38,1],[[30,0,[\"didInsert\"]]],null],[4,[38,2],[[30,0,[\"willDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@label\",\"@value\",\"@errorMessage\"],[[28,[37,4],[\"contents.offers.offer.timespan.properties.price\"],null],[30,0,[\"changeset\",\"amount\"]],[30,0,[\"changeset\",\"error\",\"amount\",\"validation\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,5],[[30,0,[\"uiAppSettings\",\"currency\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@label\",\"@isSecondary\",\"@onClick\"],[[28,[37,4],[\"global.actions.save\"],null],true,[30,0,[\"save\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@isOpen\",\"@onDiscard\"],[[30,0,[\"isDiscardChangesDialog\"]],[30,0,[\"discardChanges\"]]]],null]],[\"m\",\"c\",\"input\"],false,[\"ui-modal\",\"did-insert\",\"will-destroy\",\"ui-input\",\"t\",\"currency-symbol\",\"ui-button\",\"ui-discard-changes\"]]",
    "moduleName": "additive-content/components/ac-contents/offers/timespan-room-dialog.hbs",
    "isStrictMode": false
  });
  /**
   * enables editing of a timespan-room
   *
   * @class AcContentsOffersTimespanRoomDialogComponent
   */
  let AcContentsOffersTimespanRoomDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AcContentsOffersTimespanRoomDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _initializerDefineProperty(this, "uiToast", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      /**
       * the model that should be edited
       *
       * @argument model
       * @type {Object}
       */
      _initializerDefineProperty(this, "model", _descriptor5, this);
      /**
       * the id the of the parent offer
       *
       * @argument offerId
       * @type {String}
       */
      _initializerDefineProperty(this, "offerId", _descriptor6, this);
      /**
       * the id the of the parent timespan
       *
       * @argument timespanId
       * @type {String}
       */
      _initializerDefineProperty(this, "timespanId", _descriptor7, this);
      /**
       * the action that closes the dialog
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClose", _descriptor8, this);
      /**
       * the internal changeset
       *
       * @property changeset
       * @type {Changeset}
       */
      _initializerDefineProperty(this, "changeset", _descriptor9, this);
      /**
       * whether the discard changes dialog is shown
       *
       * @property isDiscardChangesDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isDiscardChangesDialog", _descriptor10, this);
      /**
       * whether the form has been touched
       *
       * @property isTouched
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isTouched", _descriptor11, this);
    }
    didInsert() {
      const validation = (0, _timespan.timespanRoomValidation)(this.intl);
      this.changeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(validation), validation);
    }
    willDestroy() {
      var _this$changeset;
      super.willDestroy(...arguments);
      (_this$changeset = this.changeset) === null || _this$changeset === void 0 || _this$changeset.destroy();
    }
    async save() {
      this.isTouched = true;
      await this.changeset.validate();
      if (!this.changeset.get('isValid')) return;
      try {
        await this.changeset.save({
          adapterOptions: {
            offer: this.offerId,
            timespan: this.timespanId
          }
        });
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.onClose();
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    close() {
      if (this.changeset.get('isDirty')) {
        this.isDiscardChangesDialog = true;
      } else {
        this.onClose();
      }
    }
    discardChanges() {
      var _this$model;
      this.changeset.rollback();
      (_this$model = this.model) === null || _this$model === void 0 || _this$model.rollbackAttributes();
      this.onClose();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "offerId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "timespanId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isDiscardChangesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsOffersTimespanRoomDialogComponent);
});
define("additive-content/components/ac-landing-pages/configurator/dropzone", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals", "@ember/runloop", "prop-types", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals, _runloop, _propTypes, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id="{{this._elementId}}"
    class="{{this._classNames}} {{@classNames}}"
    {{did-insert this.onInsertElement}}
  >
    {{#if this.showDropzone}}
      <div class="ac-landing-pages-configurator-dropzone-container mw-100 ba br1"></div>
    {{/if}}
  </div>
  */
  {
    "id": "N9mch7Qy",
    "block": "[[[11,0],[16,1,[29,[[30,0,[\"_elementId\"]]]]],[16,0,[29,[[30,0,[\"_classNames\"]],\" \",[30,1]]]],[4,[38,0],[[30,0,[\"onInsertElement\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"showDropzone\"]],[[[1,\"    \"],[10,0],[14,0,\"ac-landing-pages-configurator-dropzone-container mw-100 ba br1\"],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@classNames\"],false,[\"did-insert\",\"if\"]]",
    "moduleName": "additive-content/components/ac-landing-pages/configurator/dropzone.hbs",
    "isStrictMode": false
  });
  /**
   * The dropzone for the content blocks
   *
   * @module ac-landing-pages
   * @class AcLandingPagesConfiguratorDropzoneComponent
   */
  let AcLandingPagesConfiguratorDropzoneComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _class = class AcLandingPagesConfiguratorDropzoneComponent extends _component2.default {
    get _classNames() {
      let classNames = 'ac-landing-pages-configurator-dropzone pv3 ma-auto';
      if (this._isHovered) {
        classNames += ' ac-landing-pages-configurator-dropzone__hovered';
      }
      if (this.showDropzone) {
        classNames += ' ac-landing-pages-configurator-dropzone__visible';
      }
      return classNames;
    }

    /**
     * whether this component is hovered or not
     *
     * @computed _isHovered
     * @type {Boolean}
     * @default false
     * @private
     */
    get _isHovered() {
      var _this$_element;
      return this.hoveredDropzoneId === ((_this$_element = this._element) === null || _this$_element === void 0 ? void 0 : _this$_element.id);
    }
    constructor() {
      super(...arguments);
      /**
       * the id of the currently hovered dropzone
       *
       * @argument hoveredDropzoneId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "hoveredDropzoneId", _descriptor, this);
      /**
       * whether the component is visible
       *
       * @argument visible
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "showDropzone", _descriptor2, this);
      /**
       * the main HTML element of this component
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _defineProperty(this, "_element", null);
      /**
       * the element id
       *
       * @property _elementId
       * @type {String}
       * @default null
       * @private
       */
      _defineProperty(this, "_elementId", null);
      this._elementId = (0, _internals.guidFor)(this);
    }
    onInsertElement(element) {
      this._element = element;
      this._onDragOver = (0, _runloop.bind)(this, this._onDragOver);
      if (this._element) {
        this._element.addEventListener('dragover', this._onDragOver, false);
      }
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this._element) {
        this._element.removeEventListener('dragover', this._onDragOver, false);
      }
    }
    _onDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "hoveredDropzoneId", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showDropzone", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertElement"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcLandingPagesConfiguratorDropzoneComponent);
});
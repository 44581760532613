define("additive-content/mirage/routes/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Styles = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const Styles = server => {
    server.get(`/styles`, schema => {
      return schema.styles.all();
    });
    server.get(`/styles/:id`, (schema, _ref) => {
      let {
        params: {
          id
        }
      } = _ref;
      return schema.styles.find(id);
    });
    server.put('/styles/:id', (schema, request) => {
      const requestBody = JSON.parse(request.requestBody);
      const style = schema.styles.find(request.params.id);
      style.update(requestBody);
      return style;
    });
    server.post('/styles', (schema, request) => {
      const requestBody = JSON.parse(request.requestBody);
      const newStyle = schema.styles.create(_objectSpread({}, requestBody));
      return newStyle;
    });
    server.delete('/styles/:id', (schema, _ref2) => {
      let {
        params: {
          id
        }
      } = _ref2;
      const style = schema.styles.find(id);
      return style.destroy();
    });
    server.post('/styles/:id/duplicate', (schema, request) => {
      const style = schema.styles.find(request.params.id);
      const requestBody = JSON.parse(request.requestBody);
      const styleBody = style.toJSON();
      delete styleBody.id;
      const newStyle = schema.styles.create(_objectSpread(_objectSpread({}, styleBody), requestBody));
      return newStyle;
    });
    server.get('/styles/:id/blocks', schema => {
      return schema.styleBlocks.all();
    });
  };
  _exports.Styles = Styles;
});
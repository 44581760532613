define("additive-content/components/ac-widgets/ac-widget-configuration/tracking", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PanelGroup @title={{t "configurator.tracking.title"}} as |group|>
    <group.item @label={{t "configurator.tracking.google"}} @isDisabled={{true}}>
      <UiSwitch
        @value={{this.changeset.tracking.googleAnalytics}}
        @isReadOnly={{this.isReadOnly}}
        @onChange={{fn (mut this.changeset.tracking.googleAnalytics)}}
      />
    </group.item>
    <group.item @label={{t "configurator.tracking.facebook"}} @isDisabled={{true}}>
      <UiSwitch
        @value={{this.changeset.tracking.facebook}}
        @isReadOnly={{this.isReadOnly}}
        @onChange={{fn (mut this.changeset.tracking.facebook)}}
      />
    </group.item>
    <div class="font-sm secondary pt2">
      {{t "configurator.helpTexts.tracking.description"}}
    </div>
  </PanelGroup>
  */
  {
    "id": "e46og2Qw",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"configurator.tracking.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"item\"]],null,[[\"@label\",\"@isDisabled\"],[[28,[37,1],[\"configurator.tracking.google\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@isReadOnly\",\"@onChange\"],[[30,0,[\"changeset\",\"tracking\",\"googleAnalytics\"]],[30,0,[\"isReadOnly\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"changeset\",\"tracking\",\"googleAnalytics\"]]],null]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"item\"]],null,[[\"@label\",\"@isDisabled\"],[[28,[37,1],[\"configurator.tracking.facebook\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@isReadOnly\",\"@onChange\"],[[30,0,[\"changeset\",\"tracking\",\"facebook\"]],[30,0,[\"isReadOnly\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"changeset\",\"tracking\",\"facebook\"]]],null]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"font-sm secondary pt2\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"configurator.helpTexts.tracking.description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"group\"],false,[\"panel-group\",\"t\",\"ui-switch\",\"fn\",\"mut\"]]",
    "moduleName": "additive-content/components/ac-widgets/ac-widget-configuration/tracking.hbs",
    "isStrictMode": false
  });
  let AcWidgetConfigurationTracking = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _class = class AcWidgetConfigurationTracking extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "changeset", _descriptor, this);
      _initializerDefineProperty(this, "isReadOnly", _descriptor2, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcWidgetConfigurationTracking);
});
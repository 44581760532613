define("additive-content/components/ac-contents/actions", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.isViewer}}
    {{#if this.nav}}
      <this.nav.action
        @label={{t "global.actions.duplicate"}}
        @icon="duplicate"
        @onClick={{this.duplicate}}
      />
    {{else}}
      <this.menuItem @label={{t "global.actions.duplicate"}} @onClick={{this.duplicate}} />
    {{/if}}
  {{/unless}}
  */
  {
    "id": "SORPibWY",
    "block": "[[[41,[51,[30,0,[\"isViewer\"]]],[[[41,[30,0,[\"nav\"]],[[[1,\"    \"],[8,[30,0,[\"nav\",\"action\"]],null,[[\"@label\",\"@icon\",\"@onClick\"],[[28,[37,2],[\"global.actions.duplicate\"],null],\"duplicate\",[30,0,[\"duplicate\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,0,[\"menuItem\"]],null,[[\"@label\",\"@onClick\"],[[28,[37,2],[\"global.actions.duplicate\"],null],[30,0,[\"duplicate\"]]]],null],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"unless\",\"if\",\"t\"]]",
    "moduleName": "additive-content/components/ac-contents/actions.hbs",
    "isStrictMode": false
  });
  let AcContentsActionsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.object), _dec5 = (0, _emberArgTypes.arg)(_propTypes.object), _dec6 = (0, _emberArgTypes.arg)(_propTypes.object), _class = class AcContentsActionsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "uiToast", _descriptor5, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor6, this);
      _initializerDefineProperty(this, "router", _descriptor7, this);
      _initializerDefineProperty(this, "store", _descriptor8, this);
      _initializerDefineProperty(this, "modelName", _descriptor9, this);
      _initializerDefineProperty(this, "editRoute", _descriptor10, this);
      _initializerDefineProperty(this, "filterKey", _descriptor11, this);
      _initializerDefineProperty(this, "model", _descriptor12, this);
      _initializerDefineProperty(this, "menuItem", _descriptor13, this);
      _initializerDefineProperty(this, "nav", _descriptor14, this);
    }
    get isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
    async duplicate() {
      const adapter = this.store.adapterFor(this.modelName);
      const baseUrl = adapter.buildURL(this.modelName, this.model.id);
      try {
        const response = await this.authenticatedFetch.fetch(`${baseUrl}/duplicate`, {
          method: 'POST',
          headers: this.model.language && {
            'Accept-Language': this.model.language
          }
        });
        if (!response || !response.ok) {
          throw new Error();
        }
        const content = await response.json();
        this.store.pushPayload(this.modelName, content);
        const contentModel = content[Object.keys(content)[0]];
        this.uiAppSettings.setLocale(contentModel.language);
        this.router.transitionTo(this.editRoute, contentModel.id, {
          queryParams: {
            language: contentModel.language
          }
        });
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "modelName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "editRoute", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "filterKey", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "menuItem", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "nav", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "duplicate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "duplicate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsActionsComponent);
});
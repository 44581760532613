define("additive-content/components/ac-styles/detail-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "ember-changeset", "ember-changeset-validations", "lodash.clonedeep", "prop-types", "@additive-apps/utils/utils/errors", "additive-content/validations/style"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _emberArgTypes, _emberChangeset, _emberChangesetValidations, _lodash, _propTypes, _errors, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal @title={{this.dialogTitle}} @size="sm" @onClose={{this.close}} as |modal|>
    <modal.header />
    <modal.content as |content|>
      <content.body {{did-insert this.setup}}>
        <UiInput
          @label={{t "components.acStyles.detailDialog.labels.name"}}
          @value={{this.changeset.name}}
          @isTouched={{this.isTouched}}
          @errorMessage={{this.changeset.error.name.validation.firstObject}}
          data-test-styles-detail-dialog-name-input
        />
  
        <UiInput
          @label={{t "components.acStyles.detailDialog.labels.description"}}
          @value={{this.changeset.description}}
          @isTouched={{this.isTouched}}
          @textarea={{true}}
        />
      </content.body>
    </modal.content>
  
    <modal.footer>
      <UiButton
        @label={{t "global.actions.save"}}
        @onClick={{this.save}}
        @isPromise={{true}}
        @isSecondary={{true}}
        data-test-styles-detail-dialog-save
      />
    </modal.footer>
  </UiModal>
  */
  {
    "id": "ACbNBdN6",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@size\",\"@onClose\"],[[30,0,[\"dialogTitle\"]],\"sm\",[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],[[4,[38,1],[[30,0,[\"setup\"]]],null]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],[[24,\"data-test-styles-detail-dialog-name-input\",\"\"]],[[\"@label\",\"@value\",\"@isTouched\",\"@errorMessage\"],[[28,[37,3],[\"components.acStyles.detailDialog.labels.name\"],null],[30,0,[\"changeset\",\"name\"]],[30,0,[\"isTouched\"]],[30,0,[\"changeset\",\"error\",\"name\",\"validation\",\"firstObject\"]]]],null],[1,\"\\n\\n      \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@isTouched\",\"@textarea\"],[[28,[37,3],[\"components.acStyles.detailDialog.labels.description\"],null],[30,0,[\"changeset\",\"description\"]],[30,0,[\"isTouched\"]],true]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],[[24,\"data-test-styles-detail-dialog-save\",\"\"]],[[\"@label\",\"@onClick\",\"@isPromise\",\"@isSecondary\"],[[28,[37,3],[\"global.actions.save\"],null],[30,0,[\"save\"]],true,true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"content\"],false,[\"ui-modal\",\"did-insert\",\"ui-input\",\"t\",\"ui-button\"]]",
    "moduleName": "additive-content/components/ac-styles/detail-dialog.hbs",
    "isStrictMode": false
  });
  /**
   * Dialog that is shown when a user wants to create or edit a style
   *
   * @module ac-styles
   * @class AcStylesDetailDialog
   */
  let AcStylesDetailDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AcStylesDetailDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor5, this);
      _initializerDefineProperty(this, "store", _descriptor6, this);
      /**
       * the changeset of the style
       *
       * @argument changeset
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor7, this);
      /**
       * whether the dialog is a edit dialog
       *
       * @argument isEditDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isEditDialog", _descriptor8, this);
      /**
       * whether the form is touched
       *
       * @argument isTouched
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isTouched", _descriptor9, this);
      /**
       * the style resource for edit
       *
       * @argument style
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "style", _descriptor10, this);
      /**
       * on close callback
       *
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor11, this);
    }
    get dialogTitle() {
      const intlText = this.isEditDialog ? 'edit' : 'create';
      return this.intl.t(`components.acStyles.detailDialog.title.${intlText}`);
    }
    async save() {
      let changeset = null;
      if (!this.args.style) {
        this.style = this.store.createRecord('style');
        const validation = (0, _style.styleValidation)(this.intl);
        const _changeset = new _emberChangeset.default(this.style, (0, _emberChangesetValidations.default)(validation), validation);
        _changeset.set('name', this.changeset.get('name'));
        _changeset.set('description', this.changeset.get('description'));
        changeset = _changeset;
      } else {
        changeset = this.changeset;
      }
      const beforeChanges = (0, _lodash.default)(this.args.style ? this.args.style.get('data') : this.style.get('data'));
      try {
        await changeset.validate();
        if (!changeset.get('isValid')) {
          throw new Error();
        }
        this.isTouched = true;
        await changeset.save();
        this.onClose();
        this.uiToast.showToast({
          type: 'success',
          title: this.intl.t('toast.success')
        });
      } catch (e) {
        const error = (0, _errors.getAdapterError)(e);
        if (error === 'plan_allowed_styles_exceeded') {
          this.uiDialog.showError(this.intl.t('components.acStyles.detailDialog.exceededError.title'), this.intl.t('components.acStyles.detailDialog.exceededError.description'));
        } else if (error === 'validation.unique') {
          this.args.style && this.changeset.set('name', beforeChanges.name);
          this.args.style && this.args.style.set('name', beforeChanges.name);
          this.changeset.pushErrors('name', this.intl.t('errors.nameExists'));
        } else {
          this.uiToast.showToast({
            type: 'error',
            title: this.intl.t('toast.unexpectedError')
          });
        }
        this.style.deleteRecord();
      }
    }
    setup() {
      const validation = (0, _style.styleValidation)(this.intl);
      this.changeset = new _emberChangeset.default(this.style || this.args.style || {
        id: '',
        name: '',
        description: ''
      }, (0, _emberChangesetValidations.default)(validation), validation);
    }
    close() {
      const changeset = this.changeset;
      if (changeset.get('isDirty')) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          this.changeset = null;
          this.onClose();
        });
        return;
      }
      this.changeset = null;
      this.onClose();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isEditDialog", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "style", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcStylesDetailDialog);
});
define("additive-content/controllers/instance/styles/style/index", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/service", "@ember/object/computed", "@ember/object", "lodash.merge"], function (_exports, _controller, _tracking, _service, _computed, _object, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceStylesStyleIndexController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _class = class InstanceStylesStyleIndexController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "aeSaveStack", _descriptor, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "uiCd", _descriptor5, this);
      _initializerDefineProperty(this, "activeTab", _descriptor6, this);
      _initializerDefineProperty(this, "tabs", _descriptor7, this);
      _initializerDefineProperty(this, "organizationSlug", _descriptor8, this);
    }
    get _isViewer() {
      return this.currentUser.hasReadOnlyLPsAndWidgets;
    }
    get _model() {
      if (!this.model || !this.uiCd.corporateDesignColors) {
        return null;
      }
      Object.keys(this.model.styles.colors).forEach(key => {
        const syncedColor = this.model.styles.colors[key].syncedValue;

        //  eslint-disable-next-line ember/no-side-effects
        this.model.styles.colors[key].syncedColor = this.uiCd.corporateDesignColors[syncedColor].color;

        //  eslint-disable-next-line ember/no-side-effects
        this.model.styles.colors[key].syncedContrastColor = this.uiCd.corporateDesignColors[syncedColor].contrastColor;
      });
      return this.model;
    }
    async onUpdate(key, value) {
      if (typeof value === 'object') {
        const oldValue = this.model.get(`styles.${key}`);
        value = (0, _lodash.default)({}, oldValue, value);
      }

      // create nested object from key with dot notation
      const nestedObject = {};
      key.split('.').reduce((aggregator, part, index, parts) => {
        aggregator[part] = index === parts.length - 1 ? value : {};
        return aggregator[part];
      }, nestedObject);
      this.model.set(`styles`, (0, _lodash.default)({}, this.model.styles, nestedObject));
      const currentStyleBlockChanges = [];
      this.aeSaveStack._stack.forEach(changedModel => {
        var _changedModel$constru;
        if (((_changedModel$constru = changedModel.constructor) === null || _changedModel$constru === void 0 ? void 0 : _changedModel$constru.modelName) !== 'style-block') {
          return;
        }
        currentStyleBlockChanges.push(changedModel.serialize({
          includeId: true
        }));
      });
      currentStyleBlockChanges.forEach(currentStyleBlockChange => {
        const storeModel = this.store.peekRecord('style-block', currentStyleBlockChange.id);
        const newStyle = [];
        currentStyleBlockChange.style.forEach(styleGroup => {
          const style = {
            id: styleGroup.id,
            name: styleGroup.name,
            properties: []
          };
          styleGroup.properties.forEach(styleProperty => {
            const changes = {
              value: styleProperty.value
            };
            if (Object.keys(styleProperty).indexOf('sync') > -1) {
              changes.sync = styleProperty.sync;
            }
            style.properties.push(Object.assign({}, styleProperty, changes));
          });
          newStyle.push(style);
        });
        storeModel.set('style', newStyle);
      });
      await this.model.save({
        adapterOptions: {
          isPreview: true
        }
      });
      this.aeSaveStack.pushChanges(this.model);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "aeSaveStack", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiCd", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.tabs[0];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tabs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [{
        title: this.intl.t('styles.configurator.general.title'),
        name: 'general'
      }, {
        title: this.intl.t('styles.configurator.colors.title'),
        name: 'colors'
      }, {
        title: this.intl.t('styles.configurator.typography.title'),
        name: 'typography'
      }];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _class);
});
define("additive-content/components/ac-contents/create-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-arg-types", "@ember/object", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _service, _emberArgTypes, _object, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiModal
    @title={{t "contents.common.newContent.title"}}
    @modalClassNames="ac-create-dialog"
    @size="md"
    @onClose={{action this.onClose}} as |m|
  >
    <m.header />
    <m.content @classNames="pb0" as |c|>
      <c.body>
        <UiItemGroup @class="w-100 pv0">
          {{yield (hash item=(component "ui-item" class="pa2 mb05") goTo=(action "goTo"))}}
        </UiItemGroup>
      </c.body>
    </m.content>
  </UiModal>
  */
  {
    "id": "FGBl85Kl",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@modalClassNames\",\"@size\",\"@onClose\"],[[28,[37,1],[\"contents.common.newContent.title\"],null],\"ac-create-dialog\",\"md\",[28,[37,2],[[30,0],[30,0,[\"onClose\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\"],[\"pb0\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@class\"],[\"w-100 pv0\"]],[[\"default\"],[[[[1,\"\\n        \"],[18,3,[[28,[37,5],null,[[\"item\",\"goTo\"],[[50,\"ui-item\",0,null,[[\"class\"],[\"pa2 mb05\"]]],[28,[37,2],[[30,0],\"goTo\"],null]]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]]],[\"m\",\"c\",\"&default\"],false,[\"ui-modal\",\"t\",\"action\",\"ui-item-group\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "additive-content/components/ac-contents/create-dialog.hbs",
    "isStrictMode": false
  });
  /**
   * The dialog used to choose between creating a new content or category
   *
   * @class ac-contents/create-dialog
   */
  let AcContentCreateDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AcContentCreateDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      /**
       * callback function executed after create dialog is closed
       *
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor2, this);
    }
    goTo(path) {
      this.router.transitionTo(path);
      this.onClose();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "goTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goTo"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentCreateDialogComponent);
});
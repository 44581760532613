define("additive-content/components/ac-contents/offers/prices-table", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiTable
    @columns={{this.columns}}
    @rows={{this.tableRows}}
    @isLoading={{this.isLoading}}
    @formats={{this.formats}}
    @classNames="ma-auto" as |ta|
  >
    <ta.head />
    <ta.body as |body|>
      <body.row
        @hasActions={{not this.isViewer}}
        @buttonAction={{hash icon="edit" action=this.onRowClick}}
      />
    </ta.body>
  </UiTable>
  
  */
  {
    "id": "eb791vvk",
    "block": "[[[8,[39,0],null,[[\"@columns\",\"@rows\",\"@isLoading\",\"@formats\",\"@classNames\"],[[30,0,[\"columns\"]],[30,0,[\"tableRows\"]],[30,0,[\"isLoading\"]],[30,0,[\"formats\"]],\"ma-auto\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"row\"]],null,[[\"@hasActions\",\"@buttonAction\"],[[28,[37,1],[[30,0,[\"isViewer\"]]],null],[28,[37,2],null,[[\"icon\",\"action\"],[\"edit\",[30,0,[\"onRowClick\"]]]]]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"ta\",\"body\"],false,[\"ui-table\",\"not\",\"hash\"]]",
    "moduleName": "additive-content/components/ac-contents/offers/prices-table.hbs",
    "isStrictMode": false
  });
  /**
   * displays the prices per room of an timespan in an offer
   *
   * @class AcContentsOffersPricesTableComponent
   */
  let AcContentsOffersPricesTableComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.array | _propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AcContentsOffersPricesTableComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor3, this);
      /**
       * whether the table is loading
       *
       * @argument isLoading
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isLoading", _descriptor4, this);
      /**
       * the raw table data
       *
       * @argument data
       * @type {Array|Object}
       */
      _initializerDefineProperty(this, "data", _descriptor5, this);
      _defineProperty(this, "columns", [{
        name: this.intl.t('contents.offers.offer.timespan.properties.rooms'),
        key: 'room'
      }, {
        name: this.intl.t('contents.offers.offer.timespan.properties.price'),
        key: 'amount',
        minWidth: '140px'
      }]);
      _defineProperty(this, "formats", {
        currency: {
          locale: this.currentUser.user.language || 'de',
          options: {
            currency: this.currentUser.currentOrganization.currency
          }
        }
      });
      /**
       * the edit action of the row
       *
       * @argument onRowClick
       * @type {Function}
       */
      _initializerDefineProperty(this, "onRowClick", _descriptor6, this);
    }
    get tableRows() {
      return this.data.map(row => [row.name, {
        value: row.amount,
        format: 'currency'
      }, row.id]);
    }
    get isViewer() {
      return this.currentUser.hasReadOnlyContents;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsOffersPricesTableComponent);
});
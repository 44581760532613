define("additive-content/components/ac-contents/detail/image", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="font-sm secondary mb1">
    {{t "contents.common.properties.image"}}
  </div>
  
  <MmResourceGallery
    @mediumType="image"
    @resources={{if this.changeset.image.content this.changeset.image.content this.changeset.image}}
    @isReadOnly={{@isReadOnly}}
    @onDropzoneClick={{this.openMultimedia}}
    @onResourceRemove={{this.removeMedium}}
    @onResourceEdit={{this.onResourceEdit}}
    @classNames="mb2"
    {{did-insert this.didInsert}}
    {{will-destroy this.willDestroy}}
    {{! Nested objects are proxied when they have not been set manually we therefore have to access the content }}
  />
  
  <MmMultimediaOverlay @onClose={{this.closeMultimedia}} @onAddMedium={{this.addMedium}}>
    {{yield}}
  </MmMultimediaOverlay>
  */
  {
    "id": "xv7WPjOP",
    "block": "[[[10,0],[14,0,\"font-sm secondary mb1\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"contents.common.properties.image\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,1],[[4,[38,3],[[30,0,[\"didInsert\"]]],null],[4,[38,4],[[30,0,[\"willDestroy\"]]],null]],[[\"@mediumType\",\"@resources\",\"@isReadOnly\",\"@onDropzoneClick\",\"@onResourceRemove\",\"@onResourceEdit\",\"@classNames\"],[\"image\",[52,[30,0,[\"changeset\",\"image\",\"content\"]],[30,0,[\"changeset\",\"image\",\"content\"]],[30,0,[\"changeset\",\"image\"]]],[30,1],[30,0,[\"openMultimedia\"]],[30,0,[\"removeMedium\"]],[30,0,[\"onResourceEdit\"]],\"mb2\"]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@onClose\",\"@onAddMedium\"],[[30,0,[\"closeMultimedia\"]],[30,0,[\"addMedium\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"@isReadOnly\",\"&default\"],false,[\"t\",\"mm-resource-gallery\",\"if\",\"did-insert\",\"will-destroy\",\"mm-multimedia-overlay\",\"yield\"]]",
    "moduleName": "additive-content/components/ac-contents/detail/image.hbs",
    "isStrictMode": false
  });
  let AcContentsDetailImageComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AcContentsDetailImageComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "multimedia", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      /**
       * the changeset containing the images
       *
       * @argument changeset
       * @type {Object}
       */
      _initializerDefineProperty(this, "changeset", _descriptor4, this);
      /**
       * the current route used to navigate to the mm engine
       *
       * @argument route
       * @type {String}
       */
      _initializerDefineProperty(this, "route", _descriptor5, this);
    }
    didInsert() {
      this.multimedia.setSelectionOptions({
        maxCount: 1,
        type: 'image',
        warningMessage: this.intl.t('global.warnings.multimedia.imageWarning')
      });
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.multimedia.resetSelectionOptions();
    }
    openMultimedia() {
      this.router.transitionTo(`${this.route}.additive-multimedia-engine`);
    }
    closeMultimedia() {
      this.router.transitionTo(this.route);
    }
    addMedium(_, _ref2) {
      let [image] = _ref2;
      this.changeset.set('image', image);
    }
    removeMedium() {
      this.changeset.set('image', null);
    }
    onResourceEdit(changedResource) {
      this.changeset.set('image', changedResource);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "multimedia", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "route", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openMultimedia", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openMultimedia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMultimedia", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeMultimedia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMedium", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addMedium"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeMedium", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeMedium"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onResourceEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onResourceEdit"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsDetailImageComponent);
});
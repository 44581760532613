define("additive-content/controllers/instance/styles/widget/index", ["exports", "additive-newsletter/config/environment", "@ember/controller", "@ember/array", "@ember/object", "ember-concurrency", "@ember/service", "@ember/object/computed", "@ember/string", "@ember/utils", "ember-changeset", "@glimmer/tracking", "additive-newsletter/utils/constants/fonts", "additive-newsletter/utils/constants/widget-default-values", "ember-changeset-validations/validators", "ember-changeset-validations", "@additive-apps/utils/utils/fonts", "lodash.clonedeep", "lodash.merge"], function (_exports, _environment, _controller, _array, _object, _emberConcurrency, _service, _computed, _string, _utils, _emberChangeset, _tracking, _fonts, _widgetDefaultValues, _validators, _emberChangesetValidations, _fonts2, _lodash, _lodash2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DEFAULT_INSTANCE = 'testhotel-post-com';
  const options = {
    selectorId: 'newsletter-preview',
    instance: DEFAULT_INSTANCE,
    locale: 'de',
    config: {
      typography: {
        embedFonts: false,
        names: {
          primary: {
            normal: '',
            light: '',
            bold: ''
          },
          secondary: {
            normal: '',
            light: '',
            bold: ''
          }
        }
      }
    },
    texts: {},
    tracking: {}
  };
  let InstanceWidgetsWidgetController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _dec2 = (0, _computed.alias)('uiAppSettings.languages.contentLanguages'), _dec3 = (0, _computed.alias)('setup.isRunning'), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      const {
        changeset
      } = this;
      yield changeset.validate();
      if (!changeset.isValid) {
        if (this.activeTab.name !== 'contents') {
          this.activeTab = this.tabs[1];
          this.uiToast.showToast({
            title: this.intl.t('configurator.contents.errorToast.title'),
            description: this.intl.t('configurator.contents.errorToast.description'),
            type: 'error'
          });
        }
        return;
      }
      const changesetChanges = changeset.get('changes');
      const changesObject = {};

      /*
       * key in changes of changeset may be a concatenated string of keys
       * so we need to create a nested object of it
       */
      changesetChanges.forEach(changedProperty => {
        const splittedPropertyKey = changedProperty.key.split('.');
        const lastKey = splittedPropertyKey.pop();
        let lastObjectLeaf = splittedPropertyKey.reduce((obj, key) => obj[key] = obj[key] || {}, changesObject);
        lastObjectLeaf[lastKey] = changedProperty.value;
      });
      let changedKeys = [];
      Object.keys(changesObject).forEach(propertyKey => {
        if (typeof changesObject[propertyKey] === 'object' && (0, _utils.isPresent)(changesObject[propertyKey])) {
          this._getChangedPropertyKeys(changesObject[propertyKey], this.modelData[propertyKey], changedKeys, propertyKey);
          return;
        }
        changedKeys.push(propertyKey);
      });

      // update color and contrastColor of custom colors if
      ['error'].forEach(colorKey => {
        const colorPrefix = `styles.colors.${colorKey}`;
        if (changedKeys.indexOf(`${colorPrefix}.color`) !== -1 || changedKeys.indexOf(`${colorPrefix}.contrastColor`) !== -1) {
          if (changedKeys.indexOf(`${colorPrefix}.color`) < 0) {
            changedKeys.push(`${colorPrefix}.color`);
          }
          if (changedKeys.indexOf(`${colorPrefix}.contrastColor`) < 0) {
            changedKeys.push(`${colorPrefix}.contrastColor`);
          }
        }
      });

      // apply changes to model
      changedKeys.forEach(changedKey => {
        const splittedKey = changedKey.split('.');

        // get new value of the changed key
        const newValue = splittedKey.reduce((object, key) => object[key], changesObject);

        // initialize key at model if it not exists
        let alreadyIntialized = '';
        splittedKey.forEach(keyPart => {
          let key = alreadyIntialized ? `${alreadyIntialized}${keyPart}` : keyPart;
          if (!this.model.get(key)) {
            this.model.set(key, {});
          }
          alreadyIntialized = `${alreadyIntialized}${keyPart}.`;
        });
        this.model.set(changedKey, newValue);
      });
      let tasks = [];
      tasks.push(this.model.save());
      tasks.push((0, _emberConcurrency.timeout)(500));
      yield (0, _emberConcurrency.all)(tasks);
      this._setupChangeset();
      this.uiToast.showToast({
        title: this.intl.t('global.toast.success.savedChanges'),
        type: 'success',
        parent: '.aw-configurator__preview'
      });
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('global.toast.error.savedChanges'),
        type: 'error',
        parent: '.aw-configurator__preview'
      });
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      this.activeTab = this.tabs[0];
      let tasks = [];
      // load corporate design definition
      const request = this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/corporate-design`);
      tasks.push(request);
      tasks.push((0, _emberConcurrency.timeout)(500));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      if (!(response !== null && response !== void 0 && response.ok)) {
        // handle widget teardown
        return;
      }
      const {
        corporateDesign
      } = yield response.json();
      this.corporateDesignTypography = corporateDesign.typography;
      if (corporateDesign.fonts) {
        yield this.loadFonts(corporateDesign.fonts);
      }
      let {
        fonts
      } = corporateDesign;
      this.fonts = fonts;
      this.fontOptions = (0, _fonts.getFontOptions)(fonts);
      let colors = {};
      Object.keys(this.model.get('styles.colors')).forEach(colorKey => {
        if (['main', 'accent', 'ambient', 'error'].indexOf(colorKey) === -1) {
          return;
        }
        const modelColor = (0, _lodash.default)(this.model.get(`styles.colors.${colorKey}`));
        const colorObject = Object.assign({}, modelColor, {
          color: modelColor.color || modelColor.syncedColor,
          contrastColor: modelColor.contrastColor || modelColor.syncedContrastColor
        });
        colors[colorKey] = colorObject;
      });
      this.model.set('styles.colors', colors);
      this.colorOptions = (0, _widgetDefaultValues.getColorOptions)(corporateDesign.colors);
      let typography = {};
      Object.keys(this.model.get('styles.typography')).forEach(typographyKey => {
        const modelTypography = (0, _lodash.default)(this.model.get(`styles.typography.${typographyKey}`));
        const typographyObject = Object.assign({}, modelTypography, {
          fontFamily: modelTypography.fontFamily || modelTypography.syncedFontFamily,
          fontSize: modelTypography.fontSize || modelTypography.syncedFontSize,
          lineHeight: modelTypography.lineHeight || modelTypography.syncedLineHeight,
          color: modelTypography.color || modelTypography.syncedColor,
          serif: modelTypography.serif || modelTypography.syncedSerif
        });
        typography[typographyKey] = typographyObject;
      });
      this.model.set('styles.typography', typography);
      if (!this.model.get('texts') || (0, _array.isArray)(this.model.get('texts'))) {
        this.model.set('texts', {});
      }
      this._setupChangeset();
      const widget = yield import("@additive-apps/newsletter-widget");
      this.instance = widget;
    } catch (error) {
      return;
    }
  }), _class = class InstanceWidgetsWidgetController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor5, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor6, this);
      _initializerDefineProperty(this, "uiState", _descriptor7, this);
      _initializerDefineProperty(this, "uiToast", _descriptor8, this);
      /**
       * the changeset for the widget
       *
       * @argument changeset
       * @type {Object}
       */
      _initializerDefineProperty(this, "changeset", _descriptor9, this);
      /**
       * the color options for the select
       *
       * @argument colorOptions
       * @type {Array}
       */
      _initializerDefineProperty(this, "colorOptions", _descriptor10, this);
      /**
       * The typography defined in the corporate design
       *
       * @property corporateDesignTypography
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "corporateDesignTypography", _descriptor11, this);
      /**
       * the font options for the select
       *
       * @argument fontOptions
       * @type {Array}
       */
      _initializerDefineProperty(this, "fontOptions", _descriptor12, this);
      /**
       * the fonts of the organizations corporate design
       *
       * @argument fonts
       * @type {Array}
       */
      _initializerDefineProperty(this, "fonts", _descriptor13, this);
      /**
       * the widget instance
       *
       * @argument instance
       * @type {Object}
       */
      _initializerDefineProperty(this, "instance", _descriptor14, this);
      /**
       * the options of the widget
       *
       * @argument options
       * @type {Object}
       */
      _initializerDefineProperty(this, "options", _descriptor15, this);
      /**
       * the background color of the widget
       *
       * @argument previewBackgroundColor
       * @type {String}
       */
      _initializerDefineProperty(this, "previewBackgroundColor", _descriptor16, this);
      _initializerDefineProperty(this, "previousPreviewBackgroundColor", _descriptor17, this);
      /**
       * the styles of the widget
       *
       * @argument styles
       * @type {Object}
       */
      _initializerDefineProperty(this, "styles", _descriptor18, this);
      /**
       * the widget texts
       *
       * @argument texts
       * @type {Object}
       */
      _initializerDefineProperty(this, "texts", _descriptor19, this);
      /**
       * the widget model
       *
       * @argument model
       * @type {Object}
       */
      _initializerDefineProperty(this, "model", _descriptor20, this);
      /**
       * the data of the model filled with default values
       *
       * @argument modelData
       * @type {Object}
       */
      _initializerDefineProperty(this, "modelData", _descriptor21, this);
      _initializerDefineProperty(this, "activeTab", _descriptor22, this);
      _initializerDefineProperty(this, "tabs", _descriptor23, this);
      _initializerDefineProperty(this, "organizationSlug", _descriptor24, this);
      _initializerDefineProperty(this, "languages", _descriptor25, this);
      /**
       * whether the data is being fetched
       *
       * @computed isLoading
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isLoading", _descriptor26, this);
      /**
       * saves the changes and creates a new changeset
       */
      _initializerDefineProperty(this, "save", _descriptor27, this);
      _initializerDefineProperty(this, "setup", _descriptor28, this);
    }
    get previewBackgroundStyle() {
      return (0, _string.htmlSafe)(`background-color: ${this.previewBackgroundColor}`);
    }
    loadFonts(fonts) {
      const fontPromises = [];
      Object.keys(fonts).forEach(key => {
        Object.keys(fonts[key]).forEach(type => {
          fonts[key][type] && fontPromises.push((0, _fonts2.loadFont)(`${key}${type}`, fonts[key][type].url));
        });
      });
      Promise.all(fontPromises).catch(error => {
        throw new Error('[WIDGET CONFIGURATOR] Could not load font', error);
      });
      return Promise.allSettled(fontPromises);
    }

    /**
     * compares two objects and returns the keys of the properties that changed
     *
     * @param {*} data the object which may have changed properties
     * @param {*} compare the object without changes
     * @param {*} changedKeys an array where the keys of the changed properties are pushed
     * @param {*} keyPrefix the concatenated keys to keep track on recursive calls
     *
     * @function _getChangedPropertyKeys
     */
    _getChangedPropertyKeys(data, compare, changedKeys, keyPrefix) {
      Object.keys(data).forEach(propertyKey => {
        const dataProperty = data[propertyKey];
        const compareProperty = compare && compare[propertyKey];
        if (typeof dataProperty === 'object' && (0, _utils.isPresent)(dataProperty)) {
          this._getChangedPropertyKeys(dataProperty, compareProperty, changedKeys, `${keyPrefix}.${propertyKey}`);
          return;
        }
        if (data[propertyKey] !== null && dataProperty !== compareProperty) {
          changedKeys.push(`${keyPrefix}.${propertyKey}`);
        }
      });
    }

    /**
     * setup changeset for the model and
     *
     * @function _setupChangeset
     */
    _setupChangeset() {
      // deep clone model data and fill with defaults
      const modelData = (0, _lodash.default)(this.model.get('data'));
      const modelStyles = modelData && modelData.styles;
      const validation = {};
      const presenceMessage = this.intl.t('errors.required');

      // fill model styles with default values and values from corporate design
      let styles = (0, _lodash2.default)({}, {
        colors: _widgetDefaultValues.StyleValues.colors,
        typography: _widgetDefaultValues.StyleValues.typography,
        globals: _widgetDefaultValues.StyleValues.globals
      }, {
        colors: modelStyles.colors,
        typography: modelStyles.typography,
        globals: modelStyles.globals
      });
      this.styles = styles;
      modelData.styles = (0, _lodash.default)(styles);
      if ((0, _array.isArray)(modelData.texts)) {
        modelData.texts = {};
      }
      this.languages.forEach(language => {
        modelData.texts[language] = (0, _lodash2.default)({}, _widgetDefaultValues.defaultTexts[language], modelData.texts[language]);
        validation[`texts.${language}.ctaButtonText`] = (0, _validators.validatePresence)({
          presence: true,
          message: presenceMessage
        });
      });
      this.modelData = modelData;
      const changeset = new _emberChangeset.default((0, _lodash.default)(modelData), (0, _emberChangesetValidations.default)(validation), validation);
      this.changeset = changeset;

      /* Initialize background-color of widget preview */
      const {
        sync,
        color,
        syncedColor
      } = styles.colors.ambient;
      if (sync) {
        this.previewBackgroundColor = syncedColor;
      } else {
        this.previewBackgroundColor = color;
      }
      this.previousPreviewBackgroundColor = color;
      const config = changeset.get('config');
      const texts = changeset.get('texts');
      const tracking = changeset.get('tracking');
      this.model.set('tracking', tracking);
      this.texts = texts;
      this.options = (0, _lodash2.default)({}, options, {
        config,
        texts: texts.de,
        type: this.model.type,
        tracking: tracking
      });
    }
    _back() {
      this.transitionToRoute('instance.styles.widgets');
      this.changeset = null;
    }
    back() {
      if (this.changeset.get('isDirty')) {
        this.uiDialog.showDiscardChangesConfirm(() => {
          this.changeset.rollback();
          this.model.rollbackAttributes();
          this._back();
        });
      } else {
        this._back();
      }
    }
    onColorUpdate(onUpdate, key, val) {
      /* We use the ambient color as background color of the widget preview */
      if (key === 'colors.ambient') {
        if (val.sync) {
          /* Use synced color */
          this.previewBackgroundColor = this.styles.colors.ambient.syncedColor;
        } else {
          if (val.color) {
            /* Use custom color */
            this.previewBackgroundColor = val.color;
            this.previousPreviewBackgroundColor = val.color;
          } else {
            /* Sync was deactivated, use previous background-color */
            this.previewBackgroundColor = this.previousPreviewBackgroundColor;
          }
        }
      }
      onUpdate(key, val);
    }
    toggleDetail() {
      this.uiState.getState('widget-detail').toggle();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "colorOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "corporateDesignTypography", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fontOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "fonts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "instance", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "previewBackgroundColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "previousPreviewBackgroundColor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "styles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "texts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "modelData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "tabs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [{
        title: this.intl.t('configurator.general.title'),
        name: 'general'
      }, {
        title: this.intl.t('configurator.contents.title'),
        name: 'contents'
      }, {
        title: this.intl.t('configurator.colors.title'),
        name: 'colors'
      }, {
        title: this.intl.t('configurator.typography.title'),
        name: 'typography'
      }, {
        title: this.intl.t('configurator.tracking.title'),
        name: 'tracking'
      }];
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "languages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "save", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "setup", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onColorUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onColorUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDetail"), _class.prototype), _class);
});
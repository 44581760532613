define("additive-content/components/ac-contents/rates/meal-type-select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "additive-content/utils/constants", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _object, _constants, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ac-meal-type-select flex flex-column mb2">
    <span class="font-sm secondary pb1">
      {{t "contents.rates.rate.properties.mealTypes.title"}}
    </span>
    <div class="ac-meal-type-select__container flex flex-row flex-wrap">
      {{#each this.mealTypes as |mealType|}}
        <UiChip
          @text={{mealType.name}}
          @isSelected={{ui-array-includes mealType this.selectedTypes "value"}}
          @isReadOnly={{@isReadOnly}}
          @onChange={{fn this.onTypeSelect mealType.value}}
          @theme="transparent"
          @classNames="mr1 mb1"
        />
      {{/each}}
    </div>
  
    <UiFormMessage @value={{this.message}} @state="error" />
  </div>
  */
  {
    "id": "mNwsBtRw",
    "block": "[[[10,0],[14,0,\"ac-meal-type-select flex flex-column mb2\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"font-sm secondary pb1\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"contents.rates.rate.properties.mealTypes.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ac-meal-type-select__container flex flex-row flex-wrap\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"mealTypes\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@text\",\"@isSelected\",\"@isReadOnly\",\"@onChange\",\"@theme\",\"@classNames\"],[[30,1,[\"name\"]],[28,[37,4],[[30,1],[30,0,[\"selectedTypes\"]],\"value\"],null],[30,2],[28,[37,5],[[30,0,[\"onTypeSelect\"]],[30,1,[\"value\"]]],null],\"transparent\",\"mr1 mb1\"]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[8,[39,6],null,[[\"@value\",\"@state\"],[[30,0,[\"message\"]],\"error\"]],null],[1,\"\\n\"],[13]],[\"mealType\",\"@isReadOnly\"],false,[\"t\",\"each\",\"-track-array\",\"ui-chip\",\"ui-array-includes\",\"fn\",\"ui-form-message\"]]",
    "moduleName": "additive-content/components/ac-contents/rates/meal-type-select.hbs",
    "isStrictMode": false
  });
  /**
   * Meal type selection in rates edit and create
   *
   * @class AcContentsRatesMealTypeSelectComponent
   */
  let AcContentsRatesMealTypeSelectComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AcContentsRatesMealTypeSelectComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      /**
       * the currently selected meal types
       *
       * @argument selectedTypes
       * @type {Array}
       */
      _initializerDefineProperty(this, "selectedTypes", _descriptor2, this);
      /**
       * the error message
       *
       * @argument errorMessage
       * @type {String}
       */
      _initializerDefineProperty(this, "errorMessage", _descriptor3, this);
      /**
       * indicates whether a ui-chip was touched
       *
       * @argument isTouched
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isTouched", _descriptor4, this);
      /**
       * the callback function that changes the selected types
       *
       * @argument onSelect
       * @type {Function}
       */
      _initializerDefineProperty(this, "onSelect", _descriptor5, this);
    }
    get mealTypes() {
      return _constants.MEAL_TYPES.map(type => {
        return {
          value: type,
          name: this.intl.t(`contents.rates.rate.properties.mealTypes.${type}`)
        };
      });
    }
    get message() {
      if (this.errorMessage && this.isTouched) {
        return this.errorMessage;
      }
      return '';
    }
    isMealTypeSelected(mealType) {
      if (this.selectedTypes.length > 0 && this.selectedTypes.contains(mealType)) {
        return true;
      }
      return false;
    }
    onTypeSelect(mealType) {
      const selectedTypes = [...(this.selectedTypes || [])];
      const indexOfType = selectedTypes.indexOf(mealType);
      if (indexOfType >= 0) {
        selectedTypes.splice(indexOfType, 1);
      } else {
        selectedTypes.push(mealType);
      }
      this.onSelect(selectedTypes);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isMealTypeSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isMealTypeSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTypeSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTypeSelect"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsRatesMealTypeSelectComponent);
});
define("additive-content/routes/instance/contents/offers/index/offer/prices", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "@additive-apps/ui/utils/dom-util"], function (_exports, _route, _object, _service, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceContentsOffersIndexOfferPricesRoute = _exports.default = (_class = class InstanceContentsOffersIndexOfferPricesRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "controller", null);
    }
    model() {
      return this.modelFor('instance.contents.offers.index.offer');
    }
    setupController(controller, model) {
      var _model$timespans;
      this.controller = controller;
      controller.offerId = model.id;
      controller.timespans = model.timespans;
      controller.activeTimespan = (_model$timespans = model.timespans) === null || _model$timespans === void 0 ? void 0 : _model$timespans.firstObject;
      if (controller.activeTimespan) {
        var _controller$activeTim;
        controller.isPricePerRoom = !((_controller$activeTim = controller.activeTimespan) !== null && _controller$activeTim !== void 0 && _controller$activeTim.amount);
        controller.fetchTimespanRooms.perform();
      }
      if (this.controller.create) {
        this.handleQueryParam();
      }
    }

    /**
     * handles the create query param
     *
     * @function handleQueryParam
     */
    async handleQueryParam() {
      this.controller.isNewTimespanDialog = true;
      this.controller.create = undefined;
      await (0, _domUtil.nextTick)();

      // reset create query param
      this.router.transitionTo({
        queryParams: {
          create: undefined
        }
      });
    }
    queryParamsDidChange(queryParams) {
      if (this.controller && queryParams.create) {
        this.handleQueryParam();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "queryParamsDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "queryParamsDidChange"), _class.prototype), _class);
});
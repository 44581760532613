define("additive-content/controllers/instance/styles/widgets", ["exports", "@ember/controller", "@ember/service", "@ember/runloop", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _service, _runloop, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceStylesWidgetsController = _exports.default = (_class = class InstanceStylesWidgetsController extends _controller.default {
    get isViewer() {
      return this.currentUser.hasReadOnlyLPsAndWidgets;
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor4, this);
      _initializerDefineProperty(this, "uiState", _descriptor5, this);
      _initializerDefineProperty(this, "uiToast", _descriptor6, this);
      _initializerDefineProperty(this, "currentUser", _descriptor7, this);
      /**
       * the widget to create
       *
       * @property newWidget
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "newWidget", _descriptor8, this);
      /**
       * the widget to delete
       *
       * @argument deleteWidget
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "deleteWidget", _descriptor9, this);
      /**
       * the widget to edit
       *
       * @property editWidget
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "editWidget", _descriptor10, this);
      /**
       * whether the delete dialog is open
       *
       * @property isDeleteDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDeleteDialog", _descriptor11, this);
      /**
       * whether the edit dialog is open
       *
       * @property isEditDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isEditDialog", _descriptor12, this);
      /**
       * whether the create dialog is open
       *
       * @property isCreateDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isCreateDialog", _descriptor13, this);
      /**
       * whether a mobile device is used
       *
       * @property isMobile
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isMobile", _descriptor14, this);
      this.resizeListener = (0, _runloop.bind)(this, this._checkWindow);
      // TODO: Listener cleanup when implementing mobile view
      window.addEventListener('resize', this.resizeListener, false);
      (0, _runloop.scheduleOnce)('afterRender', this, this.resizeListener);
    }

    /**
     * Checks the current windowsize programmatically and sets the `isMobile`-prop
     * to true if the current window size is lower than `600px`.
     *
     * @function _checkWindow
     * @private
     * @return {void}
     */
    _checkWindow() {
      this.isMobile = !window.matchMedia('(min-width: 600px)').matches;
    }

    /**
     * Opens the widget in configuration-mode
     */
    openConfigurator(widget) {
      if (widget.version === 1) return;
      if (this.isMobile) {
        this.uiDialog.showError(this.intl.t('global.errors.desktopOnly.title'), this.intl.t('global.errors.desktopOnly.widget'));
      } else {
        this.router.transitionTo('instance.styles.widgets.widget', widget);
      }
    }
    /**
     * Opens the detail of the current order
     */
    toggleDetail() {
      this.uiState.getState('order-detail').toggle();
    }
    openCreateDialog() {
      this.isCreateDialog = true;
      this.newWidget = this.store.createRecord('widget', {
        name: null,
        description: null,
        version: 2
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "newWidget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deleteWidget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "editWidget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isDeleteDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isEditDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isCreateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isMobile", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openConfigurator", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openConfigurator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCreateDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateDialog"), _class.prototype), _class);
});
define("additive-content/initializers/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(/* application */
  ) {
    const inflector = _emberInflector.default.inflector;
    inflector.irregular('point-of-interest', 'points-of-interest');
  }
  var _default = _exports.default = {
    name: 'custom-inflector-rules',
    initialize
  };
});